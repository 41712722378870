import { UserProvider } from "@auth0/nextjs-auth0";
import useSessionStorage from "beautiful-react-hooks/useSessionStorage";
import TimeAgo from "javascript-time-ago";
import fr from "javascript-time-ago/locale/fr";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { NextPage } from "next/types";
import { GoogleAnalytics } from "nextjs-google-analytics";
import React from "react";
import { Toaster } from "react-hot-toast";
import { IntercomProvider } from "react-use-intercom";
import { SWRConfig } from "swr";
import api from "utils/api";
import type { Channel } from "utils/database";

import pkg from "../../../package.json";
import Loader from "../components/Loader";
import OneSignal from "../components/OneSignal";
import Sidebar from "../components/Sidebar";
import "../styles/style.scss";
import { OrganizationWithRelations } from "../utils/hooks";
import { fetcher, useMe } from "../utils/hooks";

TimeAgo.addDefaultLocale(fr);

export type NextPageWithLayout = NextPage & {
  /* eslint-disable-next-line */
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & {
    hideSidebar?: boolean;
    /* eslint-disable-next-line */
    getLayout?: (page: React.ReactElement) => React.ReactNode;
  };
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  React.useEffect(() => {
    if (router.asPath.match("/declaration/")) {
      // @ts-ignore
      window?.Intercom("update", {
        hide_default_launcher: true,
      });
    } else {
      // @ts-ignore
      window?.Intercom("update", {
        hide_default_launcher: false,
      });
    }
  });
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <Script
        id="gtm-settings"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PQZZQL7');
      `,
        }}
      />
      {/* <Script
        id="axeptio-settings"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.axeptioSettings = {
            clientId: "63d2e87bb1cafca2f51bf861",
            cookiesVersion: "kontable-fr",
            userCookiesDomain: "kontable.fr"
          };

          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");
      `,
        }}
      /> */}

      <SWRConfig
        value={{
          refreshInterval: 5000,
          fetcher,
        }}
      >
        <GoogleAnalytics trackPageViews gaMeasurementId="G-43ERSG5BXP" />
        <IntercomProvider appId={"f9wqxsk6"}>
          <UserProvider>
            <AuthLoader>
              <div className="flex h-screen overflow-hidden bg-white">
                {!Component.hideSidebar && <Sidebar />}

                <div className="relative bg-white flex flex-col flex-1 overflow-y-auto overflow-x-hidden border-l border-slate-200">
                  {getLayout(<Component {...pageProps} />)}
                </div>
                <Toaster />
                <OneSignal />
              </div>
            </AuthLoader>
          </UserProvider>
        </IntercomProvider>
      </SWRConfig>
    </>
  );
}

function AuthLoader({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { me, mutate, isLoading } = useMe();
  // const { boot, update } = useIntercom();

  console.info("BUILD VERSION", pkg.build.version);
  const isAdmin = me?.invitations.some((invitations) =>
    invitations.role.match(/KONTABLE_ADMINISTRATOR/),
  );
  // ? Current Organization of the user
  // ? stored in localStorage
  const [currentOrganization, setCurrentOrganization] =
    useSessionStorage<OrganizationWithRelations>("currentOrganization");

  console.log("***********************");
  console.log("currentOrganization", currentOrganization);
  console.log("me", me);
  // ? Current Channel of the organization
  // ? stored in localStorage
  /* eslint-disable */
  const [currentChannel, setCurrentChannel] =
    useSessionStorage<Channel>("currentChannel");

  React.useEffect(() => {
    // Update invitation and accept it once
    const updateInvitation = async (invitation) => {
      const { data: updatedInvitation } = await api.post<any>(
        `/invitations/${invitation.id}/accept`,
      );

      mutate({
        ...me,
        invitations: me.invitations.map((invitation) =>
          invitation.id === updatedInvitation.id
            ? updatedInvitation
            : invitation,
        ),
      });
    };
    if (me && currentOrganization) {
      const currentInvitation = me.invitations.find(
        (invitation) => invitation.organization.id === currentOrganization.id,
      );
      if (currentInvitation && currentInvitation.status !== "ACCEPTED") {
        updateInvitation(currentInvitation);
      }
    }
  }, [currentOrganization, me]);

  if (me?.invitations?.length && !currentOrganization) {
    setCurrentOrganization(me.invitations[0].organization);
  }

  console.log({ me, currentOrganization });

  // ? Redirect to onboarding
  // ? if user do not have an invitation
  if (me && !me.invitations.length) {
    if (me && !me.phoneVerified && router.asPath !== "/cgu") {
      router.replace("/cgu");
    } else if (
      !router.asPath.match("/onboarding") &&
      !router.asPath.match("/cgu")
    ) {
      if (!me.firstname) {
        router.replace("/onboarding/collaborator");
      } else {
        router.replace("/onboarding/organization");
      }
      return <Loader />;
    } else {
      return <>{children}</>;
    }
  }

  // ? Redirect to onboarding
  // ? if organization do not have a siret
  if (
    me &&
    currentOrganization &&
    !isAdmin &&
    (!currentOrganization.siret || currentOrganization.siret.length !== 14)
  ) {
    if (!router.asPath.match("/onboarding")) {
      router.replace("/onboarding/organization");
      return <Loader />;
    } else {
      return <>{children}</>;
    }
  }

  // ? Set the currentOrganization
  // ? to the first organization of user by default
  if (me && me.invitations.length && !currentOrganization) {
    const accountingFirmInvitation = me.invitations.find(
      (invitation) => invitation.organization.type === "ACCOUNTING",
    );
    if (accountingFirmInvitation) {
      setCurrentOrganization(accountingFirmInvitation.organization);
    } else {
      setCurrentOrganization(
        me.invitations[me.invitations.length - 1].organization,
      );
    }
  }

  if (
    me &&
    currentOrganization &&
    currentOrganization?.channels?.length &&
    !currentChannel
  ) {
    setCurrentChannel(currentOrganization.channels[0]);
  }

  // ? Do not display pages
  // ? until the user is not loaded and hydrated
  if (!me && isLoading) {
    return <Loader />;
  }

  // ? Redirect to dashboard if user is logged
  // ? and on the signup page /
  if (me && router.asPath === "/") {
    router.replace("/dashboard");
    return <Loader />;
  }

  console.log("***********************");
  console.log("me", me);
  console.log("currentOrganization", currentOrganization);
  console.log("isLoading", isLoading);
  console.log("***********************");
  if (!me && !isLoading) {
    router.replace("/api/auth/login");
    console.log("replace by login");
    return <Loader />;
  }

  // if (me && !me.phoneVerified && router.asPath !== "/cgu") {
  //   return <Loader />;
  // }
  // ? Redirect to signup page /
  // ? if user is not authenticated and on another page
  if (!me && router.asPath !== "/") {
    router.replace("/");
    return <Loader />;
  }

  // ? Display the page
  return <>{children}</>;
}

export default MyApp;
