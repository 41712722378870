import axios from "axios";

const api = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

export const insee = axios.create({
  baseURL: "https://api.insee.fr/entreprises/sirene/V3",
  headers: {
    Authorization: `Bearer fec59354-77c7-3229-afea-930225ebe341`,
  },
});

export const auth0 = axios.create({
  baseURL: `${process.env.AUTH0_ISSUER_BASE_URL}`,
});

export const gouv = axios.create({
  baseURL: `https://entreprise.data.gouv.fr/api/sirene/v1`,
});

export const cfadock = axios.create({
  baseURL: `https://api.leguidedelataxeapprentissage.fr`,
});

export const exchangeRates = axios.create({
  baseURL: "https://api.exchangerate.host",
});
export default api;
