import {
  ArrowsRightLeftIcon,
  BuildingStorefrontIcon, // VideoCameraIcon,
  ChartBarIcon,
  ClockIcon,
  Cog6ToothIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  TrophyIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import useSessionStorage from "beautiful-react-hooks/useSessionStorage";
import Link from "next/link";
import React from "react";
import AsyncSelect from "react-select/async";
import api from "utils/api";
import type { Channel, Organization } from "utils/database";
import { OrganizationWithRelations } from "utils/hooks";
import { useMe } from "utils/hooks";

import pkg from "../../../package.json";

function Sidebar() {
  const [isSidebarOpen, setIsSidebarOpen] =
    useSessionStorage<boolean>("isSidebarOpen");

  const { me } = useMe();

  const trigger = React.useRef(null);
  const sidebar = React.useRef(null);

  const [currentOrganization, setCurrentOrganization] =
    useSessionStorage<Organization>("currentOrganization");

  /* eslint-disable */
  const [currentChannel, setCurrentChannel] =
    useSessionStorage<Channel>("currentChannel");

  const isAdmin = me?.invitations.some(
    (invitations) => invitations.role === "KONTABLE_ADMINISTRATOR",
  );
  const onChangeOrganization = (option: {
    value: OrganizationWithRelations;
    label: string;
  }) => {
    setCurrentOrganization(option.value);
    setCurrentChannel(option.value.channels[0]);

    if (option.value.type === "ADMINISTRATION")
      window.location.href = `/schools`;
    if (option.value.type === "SCHOOL") window.location.href = `/dashboard`;
    if (option.value.type === "ENTERPRISE") window.location.href = `/arrowings`;
    // location.reload(true);
  };
  // const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const storedSidebarExpanded = true;
  const [sidebarExpanded, setSidebarExpanded] = React.useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === true,
  );

  // close on click outside
  React.useEffect(() => {
    // const clickHandler = ({ target }: { target: any }) => {
    const clickHandler = () => {
      if (!sidebar.current || !trigger.current) return;
      // if (!isSidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setIsSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  React.useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!isSidebarOpen || keyCode !== 27) return;
      setIsSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  React.useEffect(() => {
    // localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          isSidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-72 lg:w-20 lg:sidebar-expanded:!w-72 2xl:!w-72 shrink-0  transition-all duration-200 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        <div className="mx-4 my-8 inline-flex lg:hidden lg:sidebar-expanded:block">
          <div className="">
            <img className="h-8" src="/logo.svg" />
          </div>
        </div>

        <div className="space-y-8 p-4">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              <div className="flex flex-col">
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/dashboard">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Tableau de bord
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/campaigns">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <MegaphoneIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Campagnes
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/search">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <MagnifyingGlassIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Recherche
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/search">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <TrophyIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Opportunités
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/search">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <BuildingStorefrontIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Listes
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/search">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <UsersIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Contacts
                      </span>
                    </a>
                  </Link>
                )}

                {currentOrganization.type === "ENTERPRISE" && (
                  <Link href="/arrowings">
                    <a className="p-2 overflow-x-hidden flex hover:text-sidebar-hover text-sidebar">
                      <ArrowsRightLeftIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Fléchages
                      </span>
                    </a>
                  </Link>
                )}
                <div className="h-6"></div>
                {currentOrganization.type === "ADMINISTRATION" && (
                  <Link href="/dashboard">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Tableau de bord
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "ADMINISTRATION" && (
                  <Link href="/schools">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Ecoles
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "ADMINISTRATION" && (
                  <Link href="/entreprises">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Entreprises
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "ADMINISTRATION" && (
                  <Link href="/kontable">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <ChartBarIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Kontable
                      </span>
                    </a>
                  </Link>
                )}
                <div className="h-6"></div>

                {currentOrganization.type === "ADMINISTRATION" && (
                  <Link href="/invitations">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <EnvelopeIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:block">
                        Invitations
                      </span>
                    </a>
                  </Link>
                )}

                <div className="h-6"></div>

                <div className="h-6"></div>

                {/* <a
                  // @ts-ignore
                  href="https://bdc.kontable.fr/"
                  target="_blank"
                  className="cursor-pointer p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900"
                >
                  <BookOpenIcon className="h-6 w-6" />
                  <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                    Base de connaissance
                  </span>
                </a>
                <a
                  // @ts-ignore
                  onClick={() => window?.Intercom("showMessages")}
                  className="cursor-pointer p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900"
                >
                  <LifebuoyIcon className="h-6 w-6" />
                  <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                    Assistance
                  </span>
                </a> */}
                <Link href="/team">
                  <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                    <UserGroupIcon className="h-6 w-6" />
                    <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                      Équipe
                    </span>
                  </a>
                </Link>
                {currentOrganization.type === "SCHOOL" && (
                  <Link href="/settings/school">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <Cog6ToothIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                        Paramètres
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "ENTERPRISE" && (
                  <Link href="/settings/arrowings">
                    <a className="p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900">
                      <Cog6ToothIcon className="h-6 w-6" />
                      <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                        Paramètres
                      </span>
                    </a>
                  </Link>
                )}
                {currentOrganization.type === "ACCOUNTING" && (
                  <a
                    // @ts-ignore
                    onClick={() => window?.Intercom("showMessages")}
                    className="cursor-pointer p-2 overflow-x-hidden flex hover:text-slate-500 text-slate-900"
                  >
                    <ClockIcon className="h-6 w-6" />
                    <span className="whitespace-nowrap ml-12 absolute font-medium lg:hidden lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:block 2xl:opacity-100 duration-200">
                      Historique
                    </span>
                  </a>
                )}

                <div className="h-6"></div>
                <div className="h-6"></div>
                <div className="h-6"></div>
              </div>
            </ul>
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="p-4">
          <AsyncSelect
            className="react-select-container"
            classNamePrefix="react-select"
            menuPlacement="top"
            onChange={onChangeOrganization as any}
            value={{
              label: currentOrganization?.name.toUpperCase(),
              value: currentOrganization,
            }}
            defaultOptions={me.invitations.map((invitation) => ({
              label: invitation.organization.name.toUpperCase(),
              value: invitation.organization,
            }))}
            loadOptions={async (inputValue) => {
              const { data } = await api.get(`/organizations`, {
                params: {
                  where: {
                    name: {
                      contains: inputValue,
                    },
                    type: {
                      in: ["ADMINISTRATION", "ACCOUNTING", "SCHOOL"],
                    },
                    origin: isAdmin ? undefined : me.origin,
                    status: !isAdmin ? "ACTIVE" : undefined,
                    creId: !isAdmin ? me.id : undefined,
                  },
                  take: 5,

                  orderBy: { name: "asc" },
                },
              });
              return data.map((org: Organization) => ({
                label: org.name.toUpperCase(),
                value: org,
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
