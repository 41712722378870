import React from "react";
import OneSignal from "react-onesignal";

import { useMe } from "../utils/hooks";

const OneSignalComponent = () => {
  const { me } = useMe();

  React.useEffect(() => {
    if (me) {
      OneSignal.init({
        appId: "5fb77432-5b65-43c1-9fd9-b18766ec3eb2",
        allowLocalhostAsSecureOrigin: true,
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: "push", // current types are "push" & "category"
                autoPrompt: true,
                text: {
                  /* limited to 90 characters */
                  actionMessage:
                    "Activer les notifications pour recevoir les messages de vos collaborateurs",
                  /* acceptButton limited to 15 characters */
                  acceptButton: "Activer",
                  /* cancelButton limited to 15 characters */
                  cancelButton: "Annuler",
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 20,
                },
              },
            ],
          },
        },
      }).then(() => {
        OneSignal.on("subscriptionChange", (isSubscribed) => {
          if (isSubscribed) {
            OneSignal.setExternalUserId(me.id);
            OneSignal.setEmail(me.email);
            OneSignal.sendTag(
              "role",
              me.invitations.some(
                (invitation: any) => invitation.role === "ACCOUNTANT",
              )
                ? "ACCOUNTANT"
                : "USER",
            );
          }
        });
      });
    }
  }, [me]);

  return null;
};

export default OneSignalComponent;
