import BarLoader from "react-spinners/BarLoader";

interface LoaderProps {
  text?: string;
}
const Loader = ({ text }: LoaderProps) => (
  <div className="h-screen w-full flex flex-col justify-center items-center">
    <BarLoader />
    <span className="mt-12">{text ? text : "Chargement..."}</span>
  </div>
);

export default Loader;
