// import useSWR from "swr";
import useSessionStorage from "beautiful-react-hooks/useSessionStorage";
import type { KeyedMutator } from "swr";
import useSWRImmutable from "swr/immutable";
import type {
  Analytic,
  Arrowing,
  Campaign,
  Channel,
  Collecte,
  Coordinate,
  Etablissement,
  Identity,
  Invitation,
  Lead,
  Message,
  Organization,
  Sponsorship,
  Unitelegale,
  User,
} from "utils/database";
import { Declaration, Feature, School } from "utils/database";

import api from "./api";

export { useSWRConfig } from "swr";

interface UseSwr<T> {
  isLoading: boolean;
  isError: boolean;
  mutate: KeyedMutator<T>;
}

export interface EtablissementWithUniteLegale extends Etablissement {
  uniteLegale: Unitelegale;
  activiteLabel?: string;
  trancheLabel?: string;
}
export const fetcher = async (resource: any, init: any) => {
  const { data } = await api.get(resource, init);
  return data;
};

export interface OrganizationWithRelations extends Organization {
  cre?: User;
  channels: Channel[];
  invitations: Invitation[];
  identities: Identity[];
}
interface InvitationWithOrganization extends Invitation {
  organization: OrganizationWithRelations;
}
export interface UserWithOrganizations extends User {
  invitations: InvitationWithOrganization[];
}
interface UseMe<T> extends UseSwr<T> {
  me: T;
}
export const useMe = (params: any = {}): UseMe<UserWithOrganizations> => {
  const { data, error, mutate } = useSWRImmutable([`/me`, params]);
  return {
    me: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseOrganizations<T> extends UseSwr<T> {
  organizations: T;
}
export const useOrganizations = (
  params: any = {},
): UseOrganizations<OrganizationWithRelations[]> => {
  const { data, error, mutate } = useSWRImmutable([`/organizations`, params]);

  return {
    organizations: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseOrganizationsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useOrganizationsStats = (
  params: any,
): UseOrganizationsStats<any[]> => {
  const { data, error, mutate } = useSWRImmutable([
    `/organizations/stats`,
    params,
  ]);

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseOrganizationsCount<T> extends UseSwr<T> {
  count: T;
}
export const useOrganizationsCount = (
  params: any,
): UseOrganizationsCount<number> => {
  const { data, error, mutate } = useSWRImmutable([
    `/organizations/count`,
    params,
  ]);

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCurrentOrganization<T> extends UseSwr<T> {
  currentOrganization: T;
}
export const useCurrentOrganization =
  (): UseCurrentOrganization<OrganizationWithRelations> => {
    const [currentOrganization, setCurrentOrganization] =
      useSessionStorage<OrganizationWithRelations>("currentOrganization");
    const { data, error, mutate } = useSWRImmutable(
      () => currentOrganization && `/organizations/${currentOrganization.id}`,
    );

    return {
      currentOrganization: data,
      isLoading: !error && !data,
      isError: error,
      mutate: (data: any) => {
        setCurrentOrganization(data);
        return mutate(data);
      },
    };
  };
interface UseCurrentChannel<T> extends UseSwr<T> {
  currentChannel: T;
}
export const useCurrentChannel = (): UseCurrentChannel<Channel> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const [currentChannel] = useSessionStorage<Channel>("currentChannel");
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      currentChannel &&
      `/organizations/${currentOrganization.id}/channels/${currentChannel.id}`,
  );

  return {
    currentChannel: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseOrganization<T> extends UseSwr<T> {
  organization: T;
}
export const useOrganization = (
  id: string | string[] | undefined,
): UseOrganization<OrganizationWithCre> => {
  const { data, error, mutate } = useSWRImmutable(
    () => id && `/organizations/${id}`,
  );

  return {
    organization: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface ChannelWithMessages extends Channel {
  messages: Message;
}
interface UseChannels<T> extends UseSwr<T> {
  channels: T;
}
export const useChannels = (): UseChannels<ChannelWithMessages[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      `/organizations/${currentOrganization.id}/channels`,
  );

  return {
    channels: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseMessages<T> extends UseSwr<T> {
  messages: T;
}
export const useMessages = (channelId: string): UseMessages<Message[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    currentOrganization &&
      channelId &&
      `/organizations/${currentOrganization.id}/channels/${channelId}/messages`,
  );

  return {
    messages: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export interface UserWithInvitations extends User {
  invitations: Invitation[];
}

interface UseUsers<T> extends UseSwr<T> {
  users: T;
}
export const useUsers = (params: any = {}): UseUsers<UserWithInvitations[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable([
    `/organizations/${currentOrganization.id}/users`,
    params,
  ]);

  return {
    users: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseUser<T> extends UseSwr<T> {
  user: T;
}
export const useUser = (userId: string): UseUser<UserWithInvitations> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      userId &&
      currentOrganization &&
      `/organizations/${currentOrganization.id}/users/${userId}`,
  );

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export const useSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] =
    useSessionStorage<boolean>("isSidebarOpen");

  // ? Add body class sidebar-expanded usable with tw like md: or hover:
  // ? Just use sidebar-expanded:
  if (isSidebarOpen) {
    document.querySelector("body")?.classList.add("sidebar-expanded");
    document.querySelector("body")?.classList.remove("sidebar-not-expanded");
  } else {
    document.querySelector("body")?.classList.add("sidebar-not-expanded");
    document.querySelector("body")?.classList.remove("sidebar-expanded");
  }

  return [isSidebarOpen, setIsSidebarOpen];
};

export const useMessageSidebar = () => {
  const [isMessageSidebarOpen, setIsMessageSidebarOpen] =
    useSessionStorage<boolean>("isMessageSidebarOpen", true);
  // ? Add body class sidebar-expanded usable with tw like md: or hover:
  // ? Just use sidebar-expanded:
  if (isMessageSidebarOpen) {
    document.querySelector("body")?.classList.add("message-sidebar-expanded");
    document
      .querySelector("body")
      ?.classList.remove("message-sidebar-not-expanded");
  } else {
    document
      .querySelector("body")
      ?.classList.add("message-sidebar-not-expanded");
    document
      .querySelector("body")
      ?.classList.remove("message-sidebar-expanded");
  }

  return [isMessageSidebarOpen, setIsMessageSidebarOpen];
};
interface ArrowingWithSchool extends Arrowing {
  school: School;
}
export interface DeclarationWithCollaborator extends Declaration {
  collaborator: User;
  arrowings: ArrowingWithSchool[];
}
interface UseDeclarations<T> extends UseSwr<T> {
  declarations: T;
}
export const useDeclarations = (
  params: any,
): UseDeclarations<DeclarationWithCollaborator[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/declarations`,
        params,
      ],
  );

  return {
    declarations: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseDeclarationsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useDeclarationsStats = (
  params: any,
): UseDeclarationsStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/declarations/stats`,
        params,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseDeclarationsCount<T> extends UseSwr<T> {
  count: T;
}
export const useDeclarationsCount = (
  params: any,
): UseDeclarationsCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/declarations/count`,
        params,
      ],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseFeatures<T> extends UseSwr<T> {
  features: T;
}
export const useFeatures = (params: any = {}): UseFeatures<Feature[]> => {
  const { data, error, mutate } = useSWRImmutable([`/features`, params]);

  return {
    features: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseConventionCollectives<T> extends UseSwr<T> {
  conventionCollectives: T;
}

export const useConventionCollectives = (
  params: any = {},
): UseConventionCollectives<any[]> => {
  const { data, error, mutate } = useSWRImmutable([`/ccns`, params]);

  return {
    conventionCollectives: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseOrigins<T> extends UseSwr<T> {
  origins: T;
}
export const useOrigins = (
  params: any = {},
): UseOrigins<UserWithInvitations[]> => {
  const { data, error, mutate } = useSWRImmutable([`/origins`, params]);

  return {
    origins: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCres<T> extends UseSwr<T> {
  cres: T;
}
export const useCres = (params: any = {}): UseCres<UserWithInvitations[]> => {
  const { data, error, mutate } = useSWRImmutable([`/cres`, params]);

  return {
    cres: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export interface OrganizationWithCre extends Organization {
  cre?: User;
  invitations: InvitationWithRelations[];
}

export interface InvitationWithRelations extends Invitation {
  user: User;
  organization: Organization;
  createdBy: User;
}
interface UseInvitationsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useInvitationsStats = (
  params: any = {},
): UseInvitationsStats<any[]> => {
  const { data, error, mutate } = useSWRImmutable([
    `/invitations/stats`,
    params,
  ]);

  return {
    stats: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseInvitationsCount<T> extends UseSwr<T> {
  count: T;
}
export const useInvitationsCount = (
  params: any,
): UseInvitationsCount<number> => {
  const { data, error, mutate } = useSWRImmutable([
    `/invitations/count`,
    params,
  ]);

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseInvitations<T> extends UseSwr<T> {
  invitations: T;
}
export const useInvitations = (
  params: any = {},
): UseInvitations<InvitationWithRelations[]> => {
  const { data, error, mutate } = useSWRImmutable([`/invitations`, params]);

  return {
    invitations: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export interface SponsorshipWithRelations extends Sponsorship {
  user: User;
  organization: Organization;
  createdBy: User;
}
interface UseSponsorshipsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useSponsorshipsStats = (
  params: any = {},
): UseSponsorshipsStats<any[]> => {
  const { data, error, mutate } = useSWRImmutable([
    `/sponsorships/stats`,
    params,
  ]);

  return {
    stats: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseSponsorshipsCount<T> extends UseSwr<T> {
  count: T;
}
export const useSponsorshipsCount = (
  params: any,
): UseSponsorshipsCount<number> => {
  const { data, error, mutate } = useSWRImmutable([
    `/sponsorships/count`,
    params,
  ]);

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseSchools<T> extends UseSwr<T> {
  schools: T;
}
export const useSchools = (params: any = {}): UseSchools<School[]> => {
  const { data, error, mutate } = useSWRImmutable([`/schools`, params]);

  return {
    schools: data || [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseArrowingsStats<T> extends UseSwr<T> {
  stats: T;
}

export const useArrowingsStats = (params: any): UseArrowingsStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/arrowings/stats`,
        params,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseArrowingsCount<T> extends UseSwr<T> {
  count: T;
}
export const useArrowingsCount = (params: any): UseArrowingsCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/arrowings/count`,
        params,
      ],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface DeclarationWithRelations extends Declaration {
  organization: Organization;
  arrowings: Arrowing[];
}
interface UsePublicDeclaration<T> extends UseSwr<T> {
  declaration: T;
}
export const usePublicDeclaration = (
  declarationHash: string,
): UsePublicDeclaration<DeclarationWithRelations> => {
  const { data, error, mutate } = useSWRImmutable(
    `/declaration/${declarationHash}`,
  );

  return {
    declaration: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

// IDENTITIES

export interface IdentityWithRelations extends Identity {
  organization: OrganizationWithCre;
}
interface UseIdentitiesStats<T> extends UseSwr<T> {
  stats: T;
}
export const useIdentitiesStats = (params: any): UseIdentitiesStats<any[]> => {
  const { data, error, mutate } = useSWRImmutable([
    `/identities/stats`,
    params,
  ]);

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseIdentitiesCount<T> extends UseSwr<T> {
  count: T;
}
export const useIdentitiesCount = (params: any): UseIdentitiesCount<number> => {
  const { data, error, mutate } = useSWRImmutable([
    `/identities/count`,
    params,
  ]);

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseIdentities<T> extends UseSwr<T> {
  identities: T;
}
export const useIdentities = (
  params: any = {},
): UseIdentities<IdentityWithRelations[]> => {
  const { data, error, mutate } = useSWRImmutable([`/identities`, params]);

  return {
    identities: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

// Etalissements
interface UseEtablissements<T> extends UseSwr<T> {
  etablissements: T;
}

export const useEtablissements = (
  params: any,
): UseEtablissements<EtablissementWithUniteLegale[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );

  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      params?.params?.where && [`/etablissements`, params],
  );

  return {
    etablissements: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseEtablissementsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useEtablissementsStats = (
  params: any,
): UseEtablissementsStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      params?.params?.where && [`/etablissements/stats`, params],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseEtablissementsCount<T> extends UseSwr<T> {
  count: T;
}
export const useEtablissementsCount = (
  params: any,
): UseEtablissementsCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      params?.params?.where && [`/etablissements/count`, params],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data && data !== 0,
    isError: error,
    mutate,
  };
};
interface UseEtablissementsCoordinates<T> extends UseSwr<T> {
  coordinates: T;
}
export const useEtablissementsCoordinates = (
  params: any,
): UseEtablissementsCoordinates<Partial<Coordinate>> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      params?.params?.where && [`/etablissements/coordinates`, params],
  );

  return {
    coordinates: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export interface LeadWithEtablissement extends Lead {
  collectes: Collecte[];
  etablissement?: EtablissementWithUniteLegale;
}
// Leads
interface UseLeads<T> extends UseSwr<T> {
  leads: T;
}

export const useLeads = (params: any): UseLeads<LeadWithEtablissement[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/leads`,
        params,
      ],
  );

  return {
    leads: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseLeadsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useLeadsStats = (params: any): UseLeadsStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/leads/stats`,
        params,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseLeadsCount<T> extends UseSwr<T> {
  count: T;
}
export const useLeadsCount = (params: any): UseLeadsCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/leads/count`,
        params,
      ],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
interface UseLeadsCoordinates<T> extends UseSwr<T> {
  coordinates: T;
}
export const useLeadsCoordinates = (
  params: any,
): UseLeadsCoordinates<Partial<Coordinate>> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/leads/coordinates`,
        params,
      ],
  );

  return {
    coordinates: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseAnalytics<T> extends UseSwr<T> {
  analytics: T;
}
export const useAnalytics = (params: any): UseAnalytics<Analytic[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/analytics`,
        params,
      ],
  );

  return {
    analytics: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

// Campaigns
interface UseCampaigns<T> extends UseSwr<T> {
  campaigns: T;
}

export const useCampaigns = (params: any): UseCampaigns<Campaign[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/campaigns`,
        params,
      ],
  );

  return {
    campaigns: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCampaign<T> extends UseSwr<T> {
  campaign: T;
}

export const useCampaign = (campaignId: string): UseCampaign<Campaign> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization &&
      campaignId && [
        `/organizations/${currentOrganization.id}/campaigns/${campaignId}`,
      ],
  );

  return {
    campaign: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCampaignStats<T> extends UseSwr<T> {
  stats: T;
}
export const useCampaignStats = (
  campaignId: string,
): UseCampaignStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/campaigns/${campaignId}/analytics`,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCampaignsStats<T> extends UseSwr<T> {
  stats: T;
}
export const useCampaignsStats = (params: any): UseCampaignsStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/campaigns/stats`,
        params,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseCampaignsCount<T> extends UseSwr<T> {
  count: T;
}
export const useCampaignsCount = (params: any): UseCampaignsCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/campaigns/count`,
        params,
      ],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
interface UseCampaignsCoordinates<T> extends UseSwr<T> {
  coordinates: T;
}
export const useCampaignsCoordinates = (
  params: any,
): UseCampaignsCoordinates<Partial<Coordinate>> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/campaigns/coordinates`,
        params,
      ],
  );

  return {
    coordinates: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

// FARID

interface UseArrowingsEnterprise<T> extends UseSwr<T> {
  declarations: T;
}
export const useArrowingsEnterprise = (
  params: any,
): UseArrowingsEnterprise<DeclarationWithCollaborator[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/arrowings-enterprise`,
        params,
      ],
  );

  return {
    declarations: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseArrowingsEnterpriseStats<T> extends UseSwr<T> {
  stats: T;
}
export const useArrowingsEnterpriseStats = (
  params: any,
): UseArrowingsEnterpriseStats<any[]> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/arrowings-enterprise/stats`,
        params,
      ],
  );

  return {
    stats: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

interface UseArrowingsEnterpriseCount<T> extends UseSwr<T> {
  count: T;
}
export const useArrowingsEnterpriseCount = (
  params: any,
): UseArrowingsEnterpriseCount<number> => {
  const [currentOrganization] = useSessionStorage<Organization>(
    "currentOrganization",
  );
  const { data, error, mutate } = useSWRImmutable(
    () =>
      currentOrganization && [
        `/organizations/${currentOrganization.id}/arrowings-enterprise/count`,
        params,
      ],
  );

  return {
    count: data ?? 0,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
